import React from "react";
import Hello from './assets/draw_the_middle.gif';

const Contact = () => {
  return (
    <div className="container page">
      <div className="intro"><b>Contact</b></div>

      <div className="section-contact">
          <div>
            <p>Animation, illustration, and merchandise inquiries contact James Dybvig at <a href="mailto: JDybvig@Gmail.com">JDybvig@Gmail.com</a></p>
            <p>Skwiz, App Development, and general inquiries contact Michael McCombie at <a href="mailto: michael@skwiz.co">michael@skwiz.co</a></p>
          </div>
          <div className="contact-image" style={{ backgroundImage: 'url(' + Hello + ')' }}></div>
      </div>
    </div>
  );
};

export default Contact;
