import './App.css';
import Header from './components/header/header.js';
import Footer from './components/footer/footer.js';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/misc/Home/Home.js';
import About from './pages/misc/About/About.js';
import Merch from './pages/misc/Merch/Merch.js';
import Contact from './pages/misc/Contact/Contact.js';

import Skwiz from './pages/projects/Skwiz/Skwiz.js';
import Ewonee from './pages/projects/Ewonee/Ewonee.js';
import Outkast from './pages/projects/Outkast/Outkast.js';
import SamEvian from './pages/projects/SamEvian/SamEvian.js';
import KrisKelly from './pages/projects/KrisKelly/KrisKelly.js';
import CityIsland from './pages/projects/CityIsland/CityIsland.js';
import TeenageEuthanasia from './pages/projects/TeenageEuthanasia/TeenageEuthanasia.js';


function App() {
    return (
        <div>
            <Header />
            <div className="body">
                <Router>
                    <Routes>
                        <Route path='/' element={<Home/>} />
                        <Route path='/about' element={<About/>} />
                        <Route path='/merch' element={<Merch/>} />
                        <Route path='/contact' element={<Contact/>} />
                        
                        <Route path='/skwiz' element={<Skwiz/>} />
                        <Route path='/11-8' element={<Ewonee/>} />
                        <Route path='/outkast' element={<Outkast/>} />
                        <Route path='/sam-evian' element={<SamEvian/>} />
                        <Route path='/kris-kelly' element={<KrisKelly/>} />
                        <Route path='/city-island' element={<CityIsland/>} />
                        <Route path='/teenage-euthanasia' element={<TeenageEuthanasia/>} />
                        
                    </Routes>
                </Router>
            </div>
            <Footer />
        </div>
    );
}

export default App;
