// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.link-button {
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    align-items: center;
    border: 2px solid #FFFFFF;
    padding: 12px 24px;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/linkbutton/linkbutton.css"],"names":[],"mappings":";;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["\n\n.link-button {\n    color: #FFFFFF;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 900;\n    line-height: 150%;\n    letter-spacing: 1px;\n    text-transform: uppercase;\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n    border: 2px solid #FFFFFF;\n    padding: 12px 24px;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
