import React, { useState } from 'react';
import '../../App.css';
import './tabs.css';
import Label from '../label/label.js';
import Project from '../project/project.js';

// Sample data
const tilesData = [
    { 
        id: 1, 
        title: 'Outkast', 
        category: 'Music Videos',
        url: './outkast',
        image: "PreviewOutkast"
    },
    { 
        id: 2, 
        title: 'Skwiz', 
        category: 'Games',
        url: './skwiz',
        image: "PreviewSkwiz"
    },
    { 
        id: 3, 
        title: 'City Island', 
        category: 'Series',
        url: './city-island',
        image: "PreviewCityIsland"
    },
    { 
        id: 4, 
        title: 'Teenage Euthanasia', 
        category: 'Series',
        url: './teenage-euthanasia',
        image: "PreviewTeenageEuthanasia"
    },
    { 
        id: 5, 
        title: 'Sam Evian', 
        category: 'Music Videos',
        url: './sam-evian',
        image: "PreviewSamEvian"
    },
    { 
        id: 6, 
        title: 'Kris Kelly', 
        category: 'Music Videos',
        url: './kris-kelly',
        image: "PreviewKrisKelly"
    },
    { 
        id: 7, 
        title: 'Ewonee', 
        category: 'Music Videos',
        url: './ewonee',
        image: "PreviewEwonee"
    }
];

const Tab = ({ label, onClick, isActive }) => (
    <div
        style={{
            color: isActive ? '#000000' : '#888888'
        }}
        className="tab-text"
        onClick={onClick}
    >
        {label}
    </div>
);

const Tile = ({ title, category, url, image }) => (
    <a href={url} className="tile">
        <div className="tile-background" id={image} />
        <div className="tile-info">
            <Project option={title}  fillColor="white" />
            <Label option={category} fillColor="white" />
        </div>
    </a>
);

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('All');

  const categories = ['All', 'Music Videos', 'Games', 'Series'];

  const filteredTiles =
    activeTab === 'All'
      ? tilesData
      : tilesData.filter((tile) => tile.category === activeTab);

  return (
    <div className="tabs container">
        <h2>All Projects</h2>
        <div className="tabs-container">
            {categories.map((category) => (
                <Tab
                    key={category}
                    label={category}
                    onClick={() => setActiveTab(category)}
                    isActive={activeTab === category}
                />
            ))}
        </div>
        <div className="tiles">
            {filteredTiles.map((tile) => (
                <Tile key={tile.id} title={tile.title} category={tile.category} url={tile.url} image={tile.image} />
            ))}
        </div>
    </div>
  );
};

export default Tabs;

