import React from "react";
import Hello from './assets/skwiz-cap.gif';

const Merch = () => {
  return (
    <div className="container page">
      <div className="intro"><b>Merch</b></div>

      <div className="section-contact">
          <div>
            <p>We still have a few Skwiz DAD caps available contact James Dybvig at <a href="mailto: JDybvig@Gmail.com">JDybvig@Gmail.com</a> if you are interested!</p>
          </div>
          <div className="contact-image" style={{ backgroundImage: 'url(' + Hello + ')' }}></div>
      </div>
    </div>
  );
};

export default Merch;
