// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
    display: flex;
    align-items: center;
    gap: 8px;
}

.label span {
    color: #ffffff;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 150%; /* 18px */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.label svg {
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/label/label.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;IAC5B,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".label {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.label span {\n    color: #ffffff;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 900;\n    line-height: 150%; /* 18px */\n    letter-spacing: 1px;\n    text-transform: uppercase;\n}\n\n.label svg {\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
