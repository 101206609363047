import React from 'react';
import './linkbutton.css';

const LinkButton = ({ text, href }) => {

  return (
    <a href={href} className="link-button">
        <span>{text}</span>
    </a>
  );
};

export default LinkButton;

