// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-arrow {
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 150%; /* 18px */
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/linkarrow/linkarrow.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;IAC5B,mBAAmB;IACnB,yBAAyB;IACzB,qBAAqB;IACrB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".link-arrow {\n    color: #FFFFFF;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 900;\n    line-height: 150%; /* 18px */\n    letter-spacing: 1px;\n    text-transform: uppercase;\n    text-decoration: none;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
