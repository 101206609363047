import React from "react";
// import Jerks4 from '../../../assets/jerks5.png';

const About = () => {
  return (
    <div className="container page">
      <div className="intro"><b>About</b></div>

      <div className="section">
          <div>
            <p>jERKS iNC. is a production studio that specializes in short form animated content. The studio was founded by James Dybvig and Michael McCombie. James is a 2D Animator/Animation Director based in Philadelphia. Michael is an iOS Developer based in California. </p>
          </div>
          {/* <div className="jerks3" style={{ backgroundImage: 'url(' + Jerks4 + ')' }}></div> */}
      </div>
      <div className="section-credits">
          <h2>Team</h2>
          <div className="grid-credits">
              <div>
                  <ul>
                      <li className="list-left">Creative Director:</li>
                      <li className="list-right">James Dybvig</li>
                  </ul>
                  <ul>
                      <li className="list-left">Developer:</li>
                      <li className="list-right">Michael McCombie</li>
                  </ul>

              </div>
              <div>
                  <ul>
                      <li className="list-left">With Help From:</li>
                        <li className="list-right">Vince Joy</li>
                        <li className="list-right">Aubrey Marks</li>
                        <li className="list-right">Mark Weaver</li>
                        <li className="list-right">Joe Basile</li>
                        <li className="list-right">Raymo Ventura</li>
                        <li className="list-right">Steve Winfield Meyer</li>
                        <li className="list-right">Donte Neal</li>
                        <li className="list-right">Austin Pettit</li>
                        <li className="list-right">Atesh Sakarya</li>
                        <li className="list-right">Hans Genares</li>
                        <li className="list-right">Jack Kubizne</li>
                        <li className="list-right">Whitney Alexander</li>
                        <li className="list-right">Gabriella Caruso</li>
                  </ul>
              </div>
          </div>
      </div>

    </div>
  );
};

export default About;
