import React from "react";
import Heading from '../../../components/heading/heading.js';
import Tabs from '../../../components/tabs/tabs.js';
import Contact from '../../../components/contact/contact.js';
import SectionSkwiz from '../../../components/section-skwiz/section-skwiz.js';

const Home = () => {
  return (
    <div>
        <Heading/>
        <Tabs/>
        <SectionSkwiz/>
        <Contact />
    </div>
  );
};

export default Home;
