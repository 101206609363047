// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/jerks-cropped.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    padding: 160px 0;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: 201px;
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-color: black;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 1rem;
    color: white;
}

.footer-socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yDAAuD;IACvD,sBAAsB;IACtB,4BAA4B;IAC5B,6BAA6B;IAC7B,4BAA4B;IAC5B,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,uBAAuB;IACvB,uBAAuB;IACvB,QAAQ;IACR,gBAAgB;IAChB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;IACR,SAAS;AACb","sourcesContent":[".footer {\n    padding: 160px 0;\n    background-image: url('../../assets/jerks-cropped.gif');\n    background-size: 201px;\n    background-position-x: right;\n    background-position-y: bottom;\n    background-repeat: no-repeat;\n    background-color: black;\n}\n\n.footer-container {\n    display: flex;\n    flex-direction: column;\n    align-content: flex-start;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 8px;\n    text-align: left;\n    font-size: 1rem;\n    color: white;\n}\n\n.footer-socials {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
